<template>
    <div class="th-list-course">
        <h2 class="th-title"><b> Data Center Dashboard</b></h2>
        <div class="row px-3 pt-3 justify-content-between" v-if="false">
            <div class="card col-xl-3 d-inline-block p-1">
                <div class="card-body">
                    <div class="d-flex flex-wrap align-items-center">
                        <div class="mr-auto">
                            <p class="text-uppercase text-dark font-weight-bold mb-1">Active Assets</p>
                            <p class="text-gray-5 mb-0">Aset dengan garansi aktif</p>
                        </div>
                        <p class="text-success font-weight-bold font-size-24 mb-0">251</p>
                    </div>
                </div>
            </div>
            <div class="card col-xl-4 d-inline-block p-1">
                <div class="card-body">
                    <div class="d-flex flex-wrap align-items-center">
                        <div class="mr-auto">
                            <p class="text-uppercase text-dark font-weight-bold mb-1">Expiring Assets</p>
                            <p class="text-gray-5 mb-0">Garansi akan berakhir dalam 3 bulan</p>
                        </div>
                        <p class="text-warning font-weight-bold font-size-24 mb-0">24</p>
                    </div>
                </div>
            </div>
            <div class="card col-xl-4 d-inline-block p-1">
                <div class="card-body">
                    <div class="d-flex flex-wrap align-items-center">
                        <div class="mr-auto">
                            <p class="text-uppercase text-dark font-weight-bold mb-1">Expired Assets</p>
                            <p class="text-gray-5 mb-0">Aset dengan garansi berakhir</p>
                        </div>
                        <p class="text-danger font-weight-bold font-size-24 mb-0">10</p>
                    </div>
                </div>
            </div>
        </div>
        <a-card>
            <div class="mb-3 row">
                <div class="col-2 ">
                    <a-input-search placeholder="Cari Nama Aset..." v-model:value="search" @input="handleSearch" />
                </div>
                <div class="col-2">
                    <a-select v-model:value="selectedFilterCustomer" class="w-100">
                        <a-select-option value="Filter Customer">Filter Customer</a-select-option>
                        <a-select-option v-for="(item, i) in filterCustomer" :key="i" :value="item.id">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-2 ">
                    <a-select v-model:value="selectedFilterLokasi" :disabled="filterLokasi.length ? false : true"
                        class="w-100">
                        <a-select-option value="Filter Lokasi">Filter Lokasi</a-select-option>
                        <a-select-option v-for="(item, i) in filterLokasi" :key="i" :value="item.id">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-2 ">
                    <a-select v-model:value="selectedFilterRuang" :disabled="filterRuang.length ? false : true"
                        class="w-100">
                        <a-select-option value="Filter Ruang">Filter Ruang</a-select-option>
                        <a-select-option v-for="(item, i) in filterRuang" :key="i" :value="item.id">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="col-2">
                    <a-select v-model:value="selectedFilterStatus" class="w-100">
                        <a-select-option value="Filter Status Asset">Filter Status Asset</a-select-option>
                        <a-select-option value="Active">Active</a-select-option>
                        <a-select-option value="Dismantled">Dismantled</a-select-option>
                        <a-select-option value="Pending">Pending</a-select-option>
                        <a-select-option value="Troubleshooting">Troubleshooting</a-select-option>
                    </a-select>
                </div>
                <div class="col-2">
                    <a-select v-model:value="selectedFilterStatusGaransi" class="w-100" @change="filterStatusGaransi">
                        <a-select-option value="Filter Status Garansi">Filter Status Garansi</a-select-option>
                        <a-select-option value="Garansi Aktif">Garansi Aktif</a-select-option>
                        <a-select-option value="Segera Berakhir">Segera Berakhir</a-select-option>
                        <a-select-option value="Garansi Berakhir">Garansi Berakhir</a-select-option>
                        <a-select-option value="Belum Di input">Belum Di input</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="w-100" style="overflow-x:auto ;">
                <a-table :pagination="globalPagination" :columns="columns" :data-source="filteredData" class="table"
                    bordered :loading="loadingTable" @change="handleTableChange">
                    <template #name="{ record }">
                        <router-link class="th-link" :to="{
            name:
                record.AssetSubCategory.assetCategoryId == 3
                    ? 'Facility Detail'
                    : record.AssetSubCategory.assetCategoryId == 1
                        ? 'Active Detail'
                        : record.AssetSubCategory.assetCategoryId == 2
                            ? 'Passive Detail'
                            : record.AssetSubCategory.assetCategoryId == 4
                                ? 'Software Detail'
                                : 'Facility Detail',
            params: { id: record.id },
        }">
                            {{ record.name ? record.name : record.id }}
                        </router-link>
                    </template>
                    <template #location="{ record }">
                        <b>
                            <HomeOutlined></HomeOutlined> {{ record.Location.name }}
                        </b> <br />
                        <small>
                            Kategori Aset: {{ record.AssetSubCategory.AssetCategory.name }} -
                            {{ record.AssetSubCategory.name }} <br />
                            Ruangan: {{ record.Room ? record.Room.name : '-' }} <br />
                            Tag Aset: {{ record.assetTag ? record.assetTag : '-' }}<br />
                            Tag Service: {{ record.serviceTag ? record.serviceTag : '-' }}
                        </small>
                    </template>
                    <template #manufacture="{ record }">
                        <b>
                            <PhoneOutlined></PhoneOutlined>
                            {{ record.Manufacturer ? record.Manufacturer.name : '-' }}
                        </b>
                        <br />
                        <small class="text-muted">
                            {{ record.Manufacturer ? `# ${record.Manufacturer.tag}` : '-' }}
                            <!-- Model <br />
                No. Produk <br />
                No.Serial <br />
                021 - 7232102 -->
                        </small>
                    </template>
                    <template #vendor="{ record }">
                        <b>
                            <ContactsOutlined />
                            <!-- {{ record.MaintenanceInformations?.length ?
            record.MaintenanceInformations[record.MaintenanceInformations.length - 1].Vendor.name ?
                record.MaintenanceInformations[MaintenanceInformations.length - 1].Vendor.name : '' :
            record.ProcurementInformation.Vendor.name ?
                record.ProcurementInformation.Vendor.name : '-' }} -->
                            {{

            record.MaintenanceInformations[record.MaintenanceInformations?.length - 1]?.Vendor ?
                record.MaintenanceInformations[0]?.Vendor.name :
                record.ProcurementInformation?.Vendor ?
                    record.ProcurementInformation?.Vendor.name : '-'
        }}
                        </b>
                        <br />
                        <small class="text-muted">
                            {{ record.Vendor ? `# ${record.Vendor.vendorSign}` : '-' }}
                            <!-- Model <br />
                No. Produk <br />
                No.Serial <br />
                021 - 7232102 -->
                        </small>
                    </template>
                    <template #assetValue="{ record }">
                        <small>PENGADAAN</small> <br />
                        IDR
                        {{
            record.ProcurementInformation
                ? convertPrice(record.ProcurementInformation?.value)
                : '-'
        }}
                        <br />
                        <small>PERAWATAN</small> <br />
                        IDR
                        {{
                record.MaintenanceInformations
                    ? convertPrice(record.MaintenanceInformations[record.MaintenanceInformations.length - 1]?.value)
                    : '-'
            }}
                        <br />
                    </template>
                    <!-- <template #description="{ }">
              <b> <HomeOutlined></HomeOutlined> Salemba </b> <br />
              <small>
                Kategori Aset: Facilities <br />
                Ruangan: Ruang Testing <br />
                Tag Aset: <br />
                Tag Service:
              </small>
            </template>
            <template #accuracy="{ }">
              <b> <PhoneOutlined></PhoneOutlined> MIKROTIK </b> <br />
              <small>
                Model <br />
                No. Produk <br />
                No.Serial <br />
                021 - 7232102
              </small>
            </template>
            <template #assetValue="{}">

              <small>PENGADAAN</small> <br />
              IDR 130.500.000 <br />
              <small>PERAWATAN</small> <br />
              IDR &nbsp; 10.100.000 <br />
            </template>
            <template #status="{ index }">

              <span v-if="index % 3 == 0">
                <a-tag :color="'green'"> Garansi Aktif</a-tag> <br
              /></span>
              <span v-if="index % 3 == 1">
                <a-tag :color="'orange'"> Segera Berakhir</a-tag> <br
              /></span>
              <span v-if="index % 3 == 2">
                <a-tag :color="'red'"> Garansi Berakhir</a-tag> <br />
              </span>
              Mulai: 07/11/2020 <br />
              Selesai: 07/11/2021
            </template> -->
                    <template #status="{ record }">
                        <!-- {{ getStatusWarranty(record).statusWarranty }} -->
                        <a-tag :color="getStatusWarranty(record).statusWarranty == 'Garansi Aktif'
            ? 'green'
            : getStatusWarranty(record).statusWarranty == 'Segera Berakhir'
                ? 'orange'
                : 'red'
            ">
                            {{ getStatusWarranty(record).statusWarranty }}</a-tag>
                        <br />

                        <!-- <span v-if="index % 3 == 0">
                <a-tag :color="'green'"> Garansi Aktif</a-tag> <br
              /></span>
              <span v-if="index % 3 == 1">
                <a-tag :color="'orange'"> Segera Berakhir</a-tag> <br
              /></span>
              <span v-if="index % 3 == 2">
                <a-tag :color="'red'"> Garansi Berakhir</a-tag> <br />
              </span> -->
                        Mulai : {{ getStatusWarranty(record).startDate }} <br />
                        Selesai : {{ getStatusWarranty(record).endDate }}
                    </template>
                    <template #statusAsset="{ record }">
                        <!-- {{ getStatusWarranty(record).statusWarranty }} -->
                        <a-tag color="green" v-if="record.status === 'Active'">
                            Active</a-tag>
                        <a-tag color="blue" v-else-if="record.status === 'Pending'">
                            Pending</a-tag>
                        <a-tag color="red" v-else-if="record.status === 'Dismantled'">
                            Dismantled</a-tag>
                        <a-tag color="yellow" v-else>
                            Troubleshoot</a-tag>
                    </template>
                </a-table>
                <!-- <a-pagination
            v-if="!isSearching"
            @change="onChangePage"
            class="float-right mt-3"
            :total="totalPages"
          /> -->
            </div>
        </a-card>
    </div>
</template>
<script>
/* eslint-disable */
import { onMounted, onBeforeUnmount, ref, reactive, createVNode, toRef, toRefs, watch, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { baseURL } from '@/services/axios'
import {
    CheckOutlined,
    ToolOutlined,
    PhoneOutlined,
    SearchOutlined,
    PlusOutlined,
    CloudUploadOutlined,
    CloudDownloadOutlined,
    ExclamationCircleOutlined,
    CloseSquareOutlined,
    InfoCircleOutlined,
    HomeOutlined,
    ContactsOutlined,
} from '@ant-design/icons-vue'
import rupiahPrice from '@/helpers/rupiahPrice'
import {
    getAddress,
    getAll,
    searchPotensi,
    remove,
    updateNasabah,
    getAllKanwil,
    getOneKC,
    getDistinct,
} from '@/services/axios/brivice-backend'
import fuzzysort from 'fuzzysort'
import { Modal, notification, message } from 'ant-design-vue'
import { useForm } from '@ant-design-vue/use'
import axios from 'axios'
import moment from 'moment'
import lodash from 'lodash'
import assetsFile from '@/data/modc_dump.json'
import { getAssets, getCustomer } from '../../services/axios/modc/assets'
import { getDashboardData } from '../../services/axios/modc/dashboard'
import { getRooms, getLocationByCustomerId } from '../../services/axios/modc/customer'
import { getLocations } from '../../services/axios/modc/users'
import {
    getVisitors, outVisitor, VisitorIn, cancelVisitors,
} from '../../services/axios/modc/receptionist'
// CHART
import { Chart, registerables } from "chart.js";
const legendPaddingPlugin = {
    id: 'legendPaddingPlugin',
    beforeInit(chart) {
        const fitValue = chart.legend.fit;
        chart.legend.fit = function fit() {
            fitValue.bind(chart.legend)();
            chart.legend.height += 20; // Menyesuaikan ini sesuai dengan jumlah padding yang Anda inginkan
        };
    }
};
Chart.register(...registerables, legendPaddingPlugin);
const columns = [
    {
        title: 'ID',
        dataIndex: 'name',
        key: 'name',
        slots: { customRender: 'name' },
        width: 100,
        sorter: true,
    },
    {
        title: 'LOKASI',
        dataIndex: 'location',
        key: 'location',
        slots: { customRender: 'location' },
    },
    {
        title: 'MANUFAKTUR',
        dataIndex: 'manufacture',
        key: 'manufacture',
        slots: { customRender: 'manufacture' },
    },
    {
        title: 'VENDOR',
        dataIndex: 'vendor',
        key: 'vendor',
        slots: { customRender: 'vendor' },
    },
    {
        title: 'STATUS GARANSI',
        dataIndex: 'status',
        key: 'status',
        slots: { customRender: 'status' },
    },
    {
        title: 'STATUS ASSET',
        dataIndex: 'status',
        key: 'status',
        slots: { customRender: 'statusAsset' },
    },

    {
        title: 'NILAI ASET',
        dataIndex: 'assetValue',
        key: 'assetValue',
        slots: { customRender: 'assetValue' },
    },
]
const columnsReceptionist = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        slots: { customRender: 'name' },
    },


    {
        title: 'Vendor',
        dataIndex: 'Vendor.name',
        key: 'category',
        // slots: { customRender: 'description' },
    },
    {
        title: 'Status',
        key: 'out',
        align: 'center',
        slots: { customRender: 'status' },
    },
    // {
    //   title: 'PIC',
    //   dataIndex: 'PIC',
    //   key: 'category',
    //   // slots: { customRender: 'description' },
    // },
    // {
    //   title: 'PIC',
    //   dataIndex: 'PIC',
    //   key: 'category',
    //   // slots: { customRender: 'description' },
    // },
    // {
    //     title: 'Keterangan',
    //     key: 'ceklis',
    //     slots: { customRender: 'action' },
    //     align: 'center',
    // },
];

export default {
    components: {
        CloudUploadOutlined,
        CloudDownloadOutlined,
        InfoCircleOutlined,
        CheckOutlined,
        ToolOutlined,
        PhoneOutlined,
        HomeOutlined,
        ContactsOutlined,
        // BarChart
    },
    setup() {
        /* eslint-disable */
        let store = useStore()
        let route = useRoute()
        // console.log(store.state.user, 'usercourse')
        let courseData = ref([])
        let modalPopUpVisibleBrispot = ref(false)
        let modalPopUpVisibleKurasi = ref(false)
        let selectedKanwil = ref(null)
        let isNasabah = ref(false)
        selectedKanwil.value = 'all'
        let potensiData = ref([])
        let indukPerusahaan = ref([])
        let isSearching = ref(false)
        let totalPages = ref([])
        let globalPagination = ref({ current: 1, pageSize: 10 })
        let search = ref('')
        let filterLokasi = ref([])
        let filterCustomer = ref([])
        let selectedFilterLokasi = ref('Filter Lokasi')
        let selectedFilterCustomer = ref('Filter Customer')
        let filterRuang = ref([])
        let selectedFilterRuang = ref('Filter Ruang')
        let selectedFilterStatus = ref('Filter Status Asset')
        let selectedFilterStatusGaransi = ref('Filter Status Garansi')
        let realData = ref([])
        const filterYear = ref(moment().year())
        const changeYear = value => {
            console.log(moment(value).year())
            filterYear.value = moment(value).year()
        };

        const filterStatusGaransi = async (e) => {
            // await fetchCourse()
            // console.log(e)
            // console.log(filteredData.value, 'ini datanya')
            if (e === 'Belum Di input') {
                filteredData.value = realData.value.filter(e => e.statusGaransi === 'Belum Di input')
            } else if (e === 'Garansi Aktif') {
                filteredData.value = realData.value.filter(e => e.statusGaransi === 'Garansi Aktif')
            } else if (e === 'Segera Berakhir') {
                filteredData.value = realData.value.filter(e => e.statusGaransi === 'Segera Berakhir')
            } else if (e === 'Garansi Berakhir') {
                filteredData.value = realData.value.filter(e => e.statusGaransi === 'Garansi Berakhir')
            } else {
                filteredData.value = realData.value
            }
        }

        const acc_token = ref(null)
        let filteredData = ref([])
        let loadingTable = ref(false)
        let sortRef = ref(null)
        let filter = reactive({
            name: null,
        })
        let pageRef = ref(null)
        let modalAddVisible = ref(false)
        const loadingAdd = ref(false)
        const router = useRouter()
        const dataAggregate = ref({
            totalVendors: 0, // V
            totalUsers: 0, // V
            totalCustomers: 0, // V
            totalLocations: 0, // V
            totalAsset: 0, // V
            totalProcurement: 0,
            totalMaintenance: 0,
            totalProcurementCost: [{ year: 2024, cost: 4 }],
            totalMaintenanceCost: [{ year: 2024, cost: 4 }],
            totalChangeRequest: 0, // V
            activeAsset: 0, // V
            pendingAsset: 0, // V
            troubleshootAsset: 0, // V
            dismantleAsset: 0, // V
        })
        const role = ref('')
        let dataReceptionist = ref([])
        const chartInstance = ref(null);
        // let myChart4 = null;
        onMounted(async () => {

            const storedUserData = localStorage.getItem('userData')
            const userData = JSON.parse(storedUserData)
            role.value = userData.user.role

            fetchCourse()

        })
        onBeforeUnmount(() => {
            if (chartInstance.value) {
                chartInstance.value.destroy();
                chartInstance.value = null;
            }
        });
        const onChangePage = page => {
            if (!isSearching.value) {
                fetchCourse(page)
            }
        }

        const onFilterMenuChange = value => {
            if (value) {
                console.log(potensiData.value, '<<<<<<<<<<<<<')
                // filteredData.value = potensiData.value.filter(d => d.indukPerusahaan === value)
                fetchCourse({ filter: value })
            } else {
                // filteredData.value = potensiData.value
                fetchCourse()
            }
            // potensiData.value = potensiList.result.filter(d => d.status === 'active')
        }

        let vendors = ref([])
        let categories = ref([])

        const onSearch = async searchText => {
            if (searchText !== '') {
                isSearching.value = true
                loadingTable.value = true
                const res = await searchPotensi(searchText)
                console.log(res, 'res search')
                // const results = fuzzysort.go(searchText, potensiData.value, { key: 'name' })
                // const sortData = results.map(result => {
                //     return result.obj
                // })
                filteredData.value = res.result
                loadingTable.value = false
            } else {
                isSearching.value = false
                loadingTable.value = false
                fetchCourse(1)
            }
        }
        const getCurrentDate = computed(() => moment())
        watch([selectedFilterLokasi, selectedFilterRuang, selectedFilterCustomer, selectedFilterStatus, filterYear], (to, from) => {
            // console.log(selectedFilterLokasi.value, '<<<<<<<<')
            console.log('ngulang>')
            fetchRooms(selectedFilterLokasi.value)
            fetchCourse(search.value, filterYear.value)
            fetchLokasi(selectedFilterCustomer.value)

        })
        const fetchCourse = async (search, year) => {
            loadingTable.value = true
            // const { value: sort } = sortRef
            // const { value: page } = pageRef
            // const { value: status } = statusPublication
            // const name = filter.name

            // const kanwils = await getAllKanwil()
            // kanwilData.value = kanwils.result

            // const potensiList = await getAll({
            //   p: globalPagination.value.current,
            //   search: search.value,
            // })
            // console.log(potensiList, '<<<<<<<<<')
            const storedUserData = localStorage.getItem('userData')
            const userData = JSON.parse(storedUserData)
            const res = await getAssets({}, selectedFilterCustomer.value, selectedFilterLokasi.value, selectedFilterRuang.value, selectedFilterStatus.value, userData.user.id, userData.user.role, search)
            const aggregate = await getDashboardData(year, userData.user.role, userData.user.id)
            dataAggregate.value = aggregate
            dataAggregate.value.totalProcurement = aggregate.totalProcurement
            dataAggregate.value.totalMaintenance = aggregate.totalMaintenance
            // dataAggregate.value.totalMaintenance = aggregate.totalMaintenance.find(
            //   e => e.year == moment().year(),
            // )
            // dataAggregate.value.totalProcurementCost = aggregate.totalProcurementCost.find(
            //   e => e.year == moment().year(),
            // )
            // dataAggregate.value.totalMaintenanceCost = aggregate.totalMaintenanceCost.find(
            //   e => e.year == moment().year(),
            // )
            // console.log(aggregate, '<<<<AGGREGATE')
            // let filteredPotensi = potensiList.result.filter(d => d.status === 'active')
            // // filteredData.value = lodash.sortBy(filteredPotensi, ['ahuPercentage', 'placePercentage','name'])
            if (res.assets.length) {

                realData.value = res.assets.filter(
                    asset => ![1, 2, 4, 9, 12, 5, 6].includes(asset.assetSubCategoryId),
                )

            } else {
                filteredData.value = []
            }
            const { customer } = await getCustomer()
            console.log(customer, 'disiniajalah')
            // const { locations } = await getLocations()
            // filterLokasi.value = locations.map(e => ({ name: e.name, id: e.id }))
            filterCustomer.value = customer.map(e => ({ name: e.name, id: e.id }))
            // potensiData.value = potensiList.result.filter(d => d.status === 'active')
            // filteredData.value = filteredData.value.map(el => {
            //   return {
            //     key: el._id,
            //     ...el,
            //   }
            // })
            // acc_token.value = localStorage.getItem('access_token')
            // if (selectedIndukPerusahaan.value) {
            //   filteredData.value = potensiList.result.filter(d => d.indukPerusahaan === selectedIndukPerusahaan.value)
            // }
            // console.log(filteredData.value, '<<<<<<ASJNDLKMALNDJKLKAM')
            if (route.query.tipe) {
                if (route.query.tipe === 'active') {
                    selectedFilterStatusGaransi.value = 'Garansi Aktif'
                    filteredData.value = realData.value.filter(e => e.statusGaransi === 'Garansi Aktif')
                } else if (route.query.tipe === 'expiring') {
                    selectedFilterStatusGaransi.value = 'Segera Berakhir'
                    filteredData.value = realData.value.filter(e => e.statusGaransi === 'Segera Berakhir')
                } else if (route.query.tipe === 'expired') {
                    selectedFilterStatusGaransi.value = 'Garansi Berakhir'
                    filteredData.value = realData.value.filter(e => e.statusGaransi === 'Garansi Berakhir')
                } else if (route.query.tipe === 'not yet') {
                    selectedFilterStatusGaransi.value = 'Belum Di input'
                    filteredData.value = realData.value.filter(e => e.statusGaransi === 'Belum Di input')
                }
            } else {
                filteredData.value = res.assets.filter(
                    asset => ![1, 2, 4, 9, 12, 5, 6].includes(asset.assetSubCategoryId),
                )
            }
            loadingTable.value = false
            isSearching.value = false
            chartAssets.value.labels = aggregate.chartAssets.labels
            chartAssets.value.datasets[0].data = aggregate.chartAssets.data
            chartChangeRequest.value.labels = aggregate.chartChangeRequest.labels
            chartChangeRequest.value.datasets[0].data = aggregate.chartChangeRequest.data
            globalPagination.value = { ...globalPagination.value, total: res.assets.length }
        }
        const fetchRooms = async (id = '') => {
            const { rooms } = await getRooms('', id)
            filterRuang.value = rooms
        }
        const fetchLokasi = async (id = '') => {
            // console.log(id, 'ini id customer buat lokasi')
            const { locations } = await getLocations('', id)
            filterLokasi.value = locations
        }
        const getStatusWarranty = record => {
            // console.log(record, 'apacb')
            const procurement = record.ProcurementInformation
            const maintenance = record.MaintenanceInformations
            if (maintenance[0]?.warrantyStart) {
                console.log('masukmt')
                const currentDate = moment()

                const mtLength = maintenance.length - 1
                // console.log(maintenance[mtLength].warrantyEnd, '<<<<<TANGGAL')
                // Parse start date using Moment.js
                const startDateMoment = moment(maintenance[mtLength].warrantyStart)
                const endDateMoment = moment(maintenance[mtLength].warrantyEnd)
                // // Calculate the end date based on the start date and warranty months
                const calculatedEndDate = startDateMoment.clone().add(maintenance[mtLength].warranty, 'months')



                const diffMonthStart = startDateMoment.diff(currentDate, 'month')
                const diffMonthEnd = calculatedEndDate.diff(currentDate, 'month')
                // console.log(currentDate.isAfter(endDateMoment.subtract(3, 'months').toDate()), '<<<DIFF', record.name)
                // console.log(currentDate, '<<<<MOMENT START')
                // console.log(endDateMoment.subtract(3, 'months').toDate(), '<<<<MOMENT END')
                // Check the warranty status
                let statusWarranty
                if (currentDate.isAfter(endDateMoment)) {
                    statusWarranty = 'Garansi Berakhir'
                } else if (currentDate.isAfter(endDateMoment.clone().subtract(3, 'months').toDate())) {
                    statusWarranty = 'Segera Berakhir'
                } else if (currentDate.isBefore(endDateMoment.clone().subtract(3, 'months').toDate())) {
                    statusWarranty = 'Garansi Aktif'
                }
                // if (Math.abs(diffMonthStart) > Math.abs(diffMonthEnd)) {
                //   statusWarranty = 'Garansi Aktif'
                // } else if (Math.abs(diffMonthStart) < Math.abs(diffMonthEnd)) {
                //   statusWarranty = 'Segera Berakhir'
                // } else if (currentDate.isAfter(calculatedEndDate)) {
                //   statusWarranty = 'Garansi Berakhir'
                // }
                const startDateValidation = startDateMoment.clone().isValid()
                const startDate = startDateValidation ? startDateMoment.clone().format('DD / MM / YYYY') : '-'
                // const endDateValidation = calculatedEndDate.clone().isValid()
                // const endDate = endDateValidation ? calculatedEndDate.clone().format('DD / MM / YYYY') : '-'
                const endDateValidation = endDateMoment.clone().isValid()
                const endDate = endDateValidation ? endDateMoment.clone().format('DD / MM / YYYY') : '-'
                record.statusGaransi = statusWarranty ? statusWarranty : 'Belum Di input'

                return {
                    statusWarranty: statusWarranty ? statusWarranty : 'Belum Di input',
                    startDate: startDate,
                    endDate: endDate,
                }
            }
            else if (procurement?.warrantyStart) {
                console.log('masuk proc')
                const currentDate = moment()

                // Parse start date using Moment.js
                const startDateMoment = moment(procurement.warrantyStart)
                const endDateMoment = moment(procurement.warrantyEnd)
                // Calculate the end date based on the start date and warranty months
                const calculatedEndDate = startDateMoment.clone().add(procurement.warranty, 'months')

                const diffMonthStart = startDateMoment.diff(currentDate, 'month')
                const diffMonthEnd = calculatedEndDate.diff(currentDate, 'month')
                // console.log(diffMonthStart, '<<<DIFF')
                // console.log(startDateMoment, '<<<<MOMENT START')
                // console.log(calculatedEndDate, '<<<<MOMENT END')
                // Check the warranty status
                let statusWarranty
                if (currentDate.isAfter(calculatedEndDate)) {
                    statusWarranty = 'Garansi Berakhir'
                } else if (currentDate.isAfter(endDateMoment.clone().subtract(3, 'months').toDate())) {
                    statusWarranty = 'Segera Berakhir'
                } else if (currentDate.isBefore(endDateMoment.clone().subtract(3, 'months').toDate())) {
                    statusWarranty = 'Garansi Aktif'
                }
                // if (Math.abs(diffMonthStart) > Math.abs(diffMonthEnd)) {
                //   statusWarranty = 'Garansi Aktif'
                // } else if (Math.abs(diffMonthStart) < Math.abs(diffMonthEnd)) {
                //   statusWarranty = 'Segera Berakhir'
                // } else if (currentDate.isAfter(calculatedEndDate)) {
                //   statusWarranty = 'Garansi Berakhir'
                // }
                const startDateValidation = startDateMoment.clone().isValid()
                const startDate = startDateValidation ? startDateMoment.clone().format('DD / MM / YYYY') : '-'
                const endDateValidation = endDateMoment.clone().isValid()
                const endDate = endDateValidation ? endDateMoment.clone().format('DD / MM / YYYY') : '-'
                return {
                    statusWarranty: statusWarranty ? statusWarranty : 'Belum Di input',
                    startDate: startDate,
                    endDate: endDate,
                }
                // if (currentDate.isAfter(startDateMoment)&&currentDate.isBefore(calculatedEndDate)) {
                //   return 'Garansi Aktif'
                // } else if (currentDate.isBefore(calculatedEndDate)) {
                //   return 'Segera Berakhir'
                // } else {
                //   return 'Garansi Berakhir'
                // }
                // const diffProcurement = moment(procurement.warrantyEnd).diff(moment(), 'month')
                // console.log(diffProcurement, '<<<<')
                // if (diffProcurement ) {

                // }
            } else {
                return {
                    statusWarranty: 'Belum Di input',
                    startDate: '-',
                    endDate: '-',
                }
            }
            // console.log(procurement, ',<<<<', '>>>>>', maintenance)
        }
        const convertPrice = value => {
            return rupiahPrice(value)
        }

        const lodashSearch = lodash.debounce(() => fetchCourse(search.value), 1500)
        const handleSearch = () => {
            globalPagination.value.current = 1
            lodashSearch()
            // fetchCourse({ search: search.value })
        }
        const handleTableChange = (pag, filters, sorter) => {
            globalPagination.value.current = pag.current
            // if (sorter) {
            //   if (sorter.columnKey == 'key') sortBy.value = '_id'
            //   else sortBy.value = sorter.columnKey
            //   order.value = sorter.order
            // }
            fetchCourse()
        }

        const goTo = (name, params) => {
            router.push({ name, params })
        }
        const handleOk = () => {
            modalAddVisible.value = false
        }

        const chartAssets = ref({
            labels: ['Paris', 'Nîmes', 'Toulon', 'Perpignan', 'Autre'],
            datasets: [
                {
                    data: [30, 40, 60, 70, 5],
                    backgroundColor: ['#89DAFF', '#81F0E5', '#A6D9F7', '#4DFFF3', '#D9F7FA', '#BFDBF7'],
                },
            ],

        });
        const optionsAssets = ref({
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                    display: false
                },
                title: {
                    display: true,
                    text: 'Assets',
                },
            },
        });
        const chartChangeRequest = ref({
            labels: ['Paris', 'Nîmes', 'Toulon', 'Perpignan', 'Autre'],
            datasets: [
                {
                    data: [30, 40, 60, 70, 5],
                    backgroundColor: ['#E59500', '#FFD25A', '#F9C846 ', '#F39C6B'],
                },
            ],

        });
        const optionsChangeRequest = ref({
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                    display: false
                },
                title: {
                    display: true,
                    text: 'Change Request',
                },
            },
        });

        return {
            changeYear,
            filterYear,
            filterStatusGaransi,
            filteredData,
            search,
            columns,
            columnsReceptionist,
            getStatusWarranty,
            convertPrice,
            handleSearch,
            dataAggregate,
            filterRuang,
            filterLokasi,
            selectedFilterLokasi,
            selectedFilterRuang,
            selectedFilterCustomer,
            getCurrentDate,
            // CHART
            chartAssets,
            optionsAssets,
            chartChangeRequest,
            optionsChangeRequest,
            filterCustomer,
            fetchLokasi,
            role,
            selectedFilterStatus,
            selectedFilterStatusGaransi,
            dataReceptionist,
        }
    },
}
</script>
<style lang="scss">
@import '../styleViews.scss';

.text-customer {
    font-size: 0.75rem;
}

.th-list-course {
    .th-link {
        color: var(--vb-color-primary) !important;

        &:hover {
            color: var(--kit-color-primary) !important;
        }
    }
}
</style>